//useFlexibleRem.ts

export function useFlexibleRem() {
  const docEl = document.documentElement;
  const dpr = window.devicePixelRatio || 1;

  // adjust body font size
  function setBodyFontSize() {
    if (document.body) {
      document.body.style.fontSize = 12 * dpr + "px";
    } else {
      document.addEventListener("DOMContentLoaded", setBodyFontSize);
    }
  }
  // setBodyFontSize();

  // set 1rem dynamically based on screen width
  function setRemUnit() {
    const designWidth = 1920; // 设计稿宽度
    const minWidth = 0; // 最小适配的屏幕宽度
    if (window.innerWidth <= minWidth) {
      docEl.style.width = minWidth + "px"

    }

    const screenWidth = Math.max(docEl.clientWidth, minWidth); // 当前屏幕宽度与最小适配宽度比较
    const rem = (screenWidth / designWidth) * 100; // 1rem = 100px at designWidth
    docEl.style.fontSize = rem + "px";
  }

  setRemUnit();

  // reset rem unit on page resize
  window.addEventListener("resize", ()=>{
    setRemUnit()
  });
  window.addEventListener("pageshow", function (e) {
    if (e.persisted) {
      setRemUnit();
    }
  });

  // detect 0.5px supports
  if (dpr >= 2) {
    const fakeBody = document.createElement("body");
    const testElement = document.createElement("div");
    testElement.style.border = ".5px solid transparent";
    fakeBody.appendChild(testElement);
    docEl.appendChild(fakeBody);
    if (testElement.offsetHeight === 1) {
      docEl.classList.add("hairlines");
    }
    docEl.removeChild(fakeBody);
  }
  
}

export function useH52Rem() {
  const root = document.documentElement;
  /** 以iPhone6为例：布局视口为375px，我们把它分成10份，则1rem = 37.5px，
   * 这时UI给定一个元素的宽为375px（设备独立像素），
   * 我们只需要将它设置为375 / 37.5 = 10rem。
  */
  const scale = root.clientWidth / 10
  root.style.fontSize = scale + 'px'  
}