<template>
  <div class="header" id="header" :style="{ background: background }">
    <!-- <img v-if="background==='transparent'" class="header-logo" src="@/statics/logo-1.png" alt /> -->
    <!-- <img v-else class="header-logo" src="@/statics/logo-2.png" alt /> -->
    <ul class="navbar">
      <li
        :class="{
          fixed: background !== 'transparent',
          active: active == '/' || active == '/home',
        }"
        @click="onNav('/')"
        class="nav nav-home"
      >
        首页
      </li>
      <li
        :class="{
          fixed: background !== 'transparent',
          active: active == '/mine',
        }"
        @click="onNav('/mine')"
        class="nav nav-about"
      >
        关于我们
      </li>
    </ul>
  </div>
</template>
<script setup>
import {
  ref,
  onMounted,
  onUnmounted,
  computed,
  defineProps,
  nextTick,
  watch,
} from "vue";

import { useRouter, useRoute } from "vue-router";
import axios from "axios";
import zhCn from "element-plus/dist/locale/zh-cn.mjs";
import jquery from "jquery";
const router = useRouter();
const route = useRoute();
const background = ref("transparent");
const active = ref("/");
watch(
  () => router.currentRoute.value.path,
  (newValue, oldValue) => {
    console.log(newValue, "newValue");
    active.value = newValue;
    if (newValue !== "/") {
      background.value = "transparent";
    } else {
      background.value = "transparent";
    }
  },
  {
    immediate: true,
    deep: true,
  }
);
const onNav = (path) => {
  const prevPath = router.currentRoute.value.path;
  if (prevPath == path) {
    window.location.reload();
  } else {
    router.push({ path });
  }
};

onMounted(() => {
  window.addEventListener("scroll", (event) => {
    const scrollTop = window.scrollY || window.pageYOffset;
    if (background.value === "fixed") return;
    if (scrollTop > 10) {
      background.value = "#fff";
    } else {
      background.value = "transparent";
    }
  });
});
onUnmounted(() => {
  window.removeEventListener("scroll", () => {});
});
</script>

<style lang="scss" scoped>
.header {
  width: 100vw;
  position: relative;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 2.4rem;
  box-sizing: border-box;
  padding: 0.32rem 0.5333rem 0.32rem 1.28rem;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  .header-logo {
    width: 2.1333rem;
  }
  .navbar {
    flex: 1;
    list-style: none;
    display: flex;
    justify-content: flex-end;

    .nav {
      font-family: PingFang SC, PingFang SC;
      font-size: 0.32rem;
      color: #ffc201;
      line-height: 0.32rem;
      text-align: left;
      padding: 0.1067rem 0.2667rem;
      border-radius: 0.5333rem;
      border: 1px solid #ffc201;
      margin-right: 0.2667rem;
      cursor: pointer;
    }
    .fixed {
      color: #ffc201;
      border-color: #ffc201;
    }
    .active {
      color: #ffc201;
      border-color: #ffc201;
    }
  }
}
</style>
