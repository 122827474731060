module.exports = {
    title: '哈奇马-宠物社交电商平台【官网】',
    BASE_URL: 'http://43.143.143.79:2002',
    TokenKey: 'Token',
    publicPath: '/',
    IosScheme: 'mzqcwhqmApp://',
    AndroidScheme: 'hqm://start.app',
    IosAppStoreUri: 'https://apps.apple.com/cn/app/%E5%93%88%E5%A5%87%E9%A9%AC%E5%AE%A0%E7%89%A9-%E6%AD%A3%E5%93%81%E8%BF%9B%E5%8F%A3%E7%8C%AB%E7%B2%AE%E8%B6%85%E4%BD%8E%E4%BB%B7%E4%B9%B0/id1609174734' || 'http://apps.apple.com/cn/app/id1609174734',
    AndroidAppStoreUri: 'https://image.hqmpet.com/apks/hqm.apk',
    appName: 'com.hyhy.petmall',
    yybAppStoreUri: 'https://a.app.qq.com/o/simple.jsp?pkgname=com.hyhy.petmall&fromcase=70051&g_f=1182517&scenevia=XQYFX',
    disableBrowser: ['weChat', 'QQ', 'QQBrowser', 'Weibo'],
}