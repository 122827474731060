import { defineStore } from 'pinia'
import { apiDictMap } from "@/api/common";
const useAppConfig = defineStore("counter", {
    state: () => ({
        companySlideshow: '',
        companyDetailImage: '',
        messageSlideshow: '',
        talentSlideshow: '',
        newsSlideshow: '',
        solutionSlideshow: '',
        productSlideshow: '',
        companyDetailVideo: ''
    }),
    persist: {
        enabled: true, // 开启缓存  默认会存储在本地localstorage
        storage: sessionStorage, // 缓存使用方式
        paths: [] // 需要缓存键 
    },
    getters: {
        companySlide() {
            return this.companySlideshow ? JSON.parse(this.companySlideshow) : []
        },
        messageSlide() {
            return this.messageSlideshow ? JSON.parse(this.messageSlideshow)[0] : []
        },
        talentSlide() {
            return this.talentSlideshow ? JSON.parse(this.talentSlideshow)[0] : []
        },
        newsSlide() {
            return this.newsSlideshow ? JSON.parse(this.newsSlideshow)[0] : []

        },
        solutionSlide() {
            return this.solutionSlideshow ? JSON.parse(this.solutionSlideshow)[0] : []

        },
        productSlide() {
            return this.productSlideshow ? JSON.parse(this.productSlideshow)[0] : []

        },
        detailImage() {
            return this.companyDetailImage
        },
        detailVideo() {
            return this.companyDetailVideo
        }

    },

    actions: {
        SET_PcConfig() {
            apiDictMap({}).then(res => {
                console.log(res.data.companySlideshow, '11111111111');

                if (res.code == 0) {
                    this.companySlideshow =JSON.stringify([{data:'http://www.tailingood.com/upload/2022/12/13/16709224702531vm1og.jpg',type:0},
                    {data:'http://www.tailingood.com/upload/2022/05/24/165338532323587fzaf.jpg',type:0},
                    {data:'http://www.tailingood.com/upload/2022/01/26/164316369372650ah7y.jpg',type:0}])|| res.data.companySlideshow
                    this.companyDetailImage = res.data.companyDetailImage
                    this.talentSlideshow = res.data.talentSlideshow
                    this.newsSlideshow = res.data.newsSlideshow
                    this.solutionSlideshow = res.data.solutionSlideshow
                    this.productSlideshow = res.data.productSlideshow
                    this.companyDetailVideo = res.data.companyDetailVideo

                }
            });
        }

    }
})
export default useAppConfig
